

:root {
    background-color: rgb(10, 0, 10);
    --text-color: rgba(245, 245, 245, 0.9);
    --hover-text-color: rgb(251, 133, 73);
    --ft-project-background: rgb(87, 68, 95);
    --skills-background-color: rgb(168, 55, 89);
}

#homeIntroDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../images/midjourney5.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 83vh;
}

#homeIntroImage {
    width: 300px;
    border-radius: 50%;
}

#myName {
    color: var(--text-color);
    font-size: 5rem;
    margin-top: -25px;
}

#jobTitle {
    margin-top: -25px;
    font-size: 2.2rem;
}

#skillsDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    height: fit-content;
    background-color: var(--skills-background-color);
    margin-top: -30px;
}

#skillsDiv h2 {
    color: rgb(21, 0, 21)
}

.ft-project-image {
    width: 170px;
    align-self: center;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

#ft-projects-title {
    width: 100%;
}

#featuredProjectsDiv{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    height: fit-content;
}

#featuredProjectsDiv p {
    font-size: 2.2rem;
}

.project {
    text-decoration: none;
    background-color: var(--ft-project-background);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.75);
    justify-content: center;
    font-size: 2.6rem;
}

.project :hover {
    color: var(--hover-text-color);
}

/* MOBILE VERSION */

@media only screen and (max-width: 600px) {
    #homeIntroDiv h3 {
        margin-top: -10px;
    }


    #myName {
        position: static;
        font-size: 3.0rem;
    }

    #jobTitle {
        position: static;
        font-size: 2.2rem;
    }

    #skillsDiv{
        margin-top: -50px;
    }

    .ft-project-image {
        width: 100px;
    }

    #skillsDiv h2 {
        font-size: 1.7rem;
    }

    #featuredProjectsDiv p {
        font-size: 2.0rem;
    }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
    
    #homeIntroImage{
        width: 200px;
    }

    #homeIntroDiv h3 {
        margin-top: -13px;
    }
    
    #myName {
        position: static;
        margin-top: -13px;
        font-size: 2.8rem;
    }

    #jobTitle {
        position: static;
        font-size: 1.7rem;
    }

    .ft-project-image {
        width: 100px;
    }

    #skillsDiv h2 {
        font-size: 1.7rem;
    }

    #featuredProjectsDiv p {
        font-size: 2.0rem;
    }
}

