

#calculatorButtonContainer {
    position: relative;
    top: 70px;
    left: 120px;
    width: 200px;
    height: fit-content;
    display: flexbox;
}

.calculatorButtons {
    width: 40px;
    height: 40px;
    background-color: rgb(28, 27, 27);
    color: beige;
    border-radius: 25%;
    margin: 12px;
}

.calculatorText {
    position: relative;
    background-color: white;
    width: 300px;
    height: 80px;
    left: 100px;
    top: 50px;
    font-size: 60px;
    text-align: right;
    align-items: center;
}

#zero {
    width: 105px;
}

#signsContainer {
    position: relative;
    width: 30px;
    left: 320px;
    top: -250px;
}

#multiply {
    background-color: rgba(176, 83, 17, 0.936);
}

#divide {
    background-color: rgba(176, 83, 17, 0.936);
}

#minus {
    background-color: rgba(176, 83, 17, 0.936);
}

#plus {
    background-color: rgba(176, 83, 17, 0.936);
}

#equals {
    background-color: rgba(176, 83, 17, 0.936);
}

#clear {
    background-color: rgb(134, 131, 131);
    color: black;
}

#negative {
    background-color: rgb(134, 131, 131);
    color: black;
}

#percent {
    background-color: rgb(134, 131, 131);
    color: black;
}

@media only screen and (max-width: 600px) {
    #calculatorButtonContainer {
        left: 45px;
        top: 80px;
    }

    #signsContainer {
        position: relative;
        width: 30px;
        left: 240px;
        top: -240px;
    }

    .calculatorText {
        left: 40px;
        top: 70px;
    }

}