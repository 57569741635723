
.board {
    position: relative;
    top: 70px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.board p {
    font-size: 4rem;
    color: black;
    z-index: 100;
}

.line {
    display: flex;
    gap: 20px;
    margin: auto;
}

.tile {
    width: 70px;
    height: 70px;
    border: 1px solid black;
    background-color: aliceblue;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;   
}

.correct {
    background-color: rgb(102, 230, 102);
}

.close {
    background-color: yellow;
}

.incorrect {
    background-color: lightgrey;
}

@media only screen and (max-width: 600px) {
    .board {
        position: relative;
        top: 45px;
        left: -10px;
        padding-bottom: 45px;
        gap: 10px;
    }

    .line {
        gap: 10px;
    }

    .tile {
        width: 50px;
        height: 50px;
    }
}