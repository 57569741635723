

.connectBoard {
    display: flex;
    position: relative;
    top: 70px;
    width: 100%;
    left: calc((100% / 2) - 285px);
}

.connectColumn {
    display: flex;
    flex-direction: column;
}

.connectTile {
    width: 80px;
    height: 80px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 248, 255, 0.822);
}

#winner {
    position: relative;
    left: 20px;
}

.turnTracker {
    position: relative;
    display: flex;
    color: whitesmoke;
    left: 20px;
}

.player{
    width: 80%;
    height: 80%;
    border-radius: 50%;
}

.player-1 {
    background-color: red;
}

.player-2 {
    background-color: blue;
}

button, input[type='button'] {
    padding: 10px;
    margin-top: 100px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #02203c;
    color: white;
    position: relative;
    margin-left: 20px;
    margin-bottom: 15%;
}

@media only screen and (max-width: 600px) {
    .connectBoard{
        position: relative;
        top: 50px;
        left: 10%;
        padding-bottom: 45px;
    }

    .connectTile{
        width: 50px;
        height: 50px;
    }

    button, input[type='button'] {
        margin-top: 20px;
    }
}