body {
  margin: 0;
}

html {
  font-size: 62.5%;
}

.wrapper-main{
  width: 100%;
}

h1 {
  font-size: 3.0rem;
  color: var(--text-color)
}

h2 {
  font-size: 2.6rem;
  color: var(--text-color)
}

h3 {
  font-size: 2.0rem;
  color: var(--text-color)
}

p {
  font-size: 1.6rem;
  color: var(--text-color)
}

/* MOBILE VERSION */

@media only screen and (max-width: 600px) {}